import React from 'react';
import { InfoProdutos } from './InfoProdutos.jsx';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import Footer from './Footer.jsx';
import ProdDestaque from './CardProdutos.jsx'
import './Css/PagProd.css';

function ProdutoPage() {
  const { id } = useParams();

  const produto = InfoProdutos.find((produto) => produto.id === Number(id));

  console.log(JSON.stringify(useParams()));
  console.log("ID da URL:" + {id});
  console.log("InfoProdutos:", InfoProdutos);
  console.log("ID procurado:", Number(id));


  const pagError = 
  <div>
    <div>Algo deu errado!</div>
    <div>Mas não fique por fora, veja nossos produtos em destaque!</div>
    <ProdDestaque/>
    <Footer></Footer>
  </div>
  if (!produto) {
    return pagError;
  }
  
    const ImagensCarrosel = produto.src.map((imagem, index) => (
    <Carousel.Item>
    <img key={index} src={imagem} alt={`${produto.nome} ${index}`} className='One-Img-Carrosel img-fluid'/>
    </Carousel.Item>
  ));

  return (
    <div className="Produto-Page">

      <div className='Container-Superior'>
      <div className='Container-Carrosel'>
      <Carousel fade = "false" className='Carousel-Box'>
       {ImagensCarrosel}
      </Carousel >
      </div>
      <div className='Nome-Produto'>
      <h1>{produto.nome}</h1>
      <div className='Descricao-Produto'>{produto.descricao}
      </div>
      </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default ProdutoPage;
