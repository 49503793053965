// Parte da página Home
//Produtos em destaque 

import React from 'react';
import './Css/CardProdutos.css';
import { Produto } from './Produto';

function CardProdutos() {

  const CadeiraBlaster = Produto(1);
  const CadeiraBrighter = Produto(2);
  const CadeiraCosmic = Produto(3);

  return (
    <div className='PrincipalProdutos'>
      <div className='boxTitle'>
        <h2>Produtos em Destaque</h2>
        
      </div>
      <div className='pag-produtos'>
      {CadeiraBlaster}
      {CadeiraBrighter}
      {CadeiraCosmic}
      </div>
      
    </div>
  );
}

export default CardProdutos;





/* import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import produto1 from '../Img/produtos/cadeirafalcon-teste.png';
import produto2 from '../Img/produtos/Produto_Two.jpg';
import produto3 from '../Img/produtos/Produto_Three.jpg'; */

















/*   const produtos = [
    {
      src: produto1,
      nome: "Cadeira Gamer",
      descricao: "Uma experiência extremamente confortável, mesmo que precise utilizar por muitas horas seguidas.",
      link: "https://www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "#"
    },
    {
      src: produto2,
      nome: "Teclado Mecânico",
      descricao: "Uma experiência extremamente confortável, mesmo que precise utilizar por muitas horas seguidas.",
      link: "https://www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "#"
    },
    {
      src: produto3,
      nome: "Mouse Gaming",
      descricao: "Uma experiência extremamente confortável, mesmo que precise utilizar por muitas horas seguidas.",
      link: "https://www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "#"
    }
  ];
 */

    /* {produtos.map((produto, index) => (
          <Card key={index} className='Card'>
            <Card.Img variant="top" src={produto.src} width={174} height={174} className='ImgCard' />
            <Card.Body>
              <Card.Title className='TitleCard'>{produto.nome}</Card.Title>
              <Card.Text>
                {produto.descricao}
              </Card.Text>
              <div className="box-button">
                <a href={produto.link}>
                  <Button variant="primary">Ver produto</Button>
                </a>
                <a href={produto.manual}>
                  <Button variant="primary">Ver manual</Button>
                </a>
              </div>
              </Card.Body>
              </Card>
            ))} */