import bannerRowLeft from '../Img/BannerRow/bannerRow1.png';
import '../Pages/Css/BannerRow.css';

function BannerRow(){
    return(
        <div className='ContainerRow'>
            <img src= {bannerRowLeft} alt="bannerLeft" className='ImgRow'/>
        </div>
    )
}
export default BannerRow;