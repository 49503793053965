import React, { useEffect } from 'react';
import useDarkMode from 'use-dark-mode';
import './Css/Header.css';
import Desligada from '../Img/icons/lampada_DES.png';
import Ligada from '../Img/icons/lampada_LIG.png';

function UseDarkMode({ onDarkModeChange }) {
  const darkMode = useDarkMode(false, {
    classNameDark: 'modo-escuro',
    classNameLight: 'modo-claro'
  });

  useEffect(() => {
    onDarkModeChange && onDarkModeChange(darkMode.value);
  }, [darkMode.value, onDarkModeChange]);

  return (
    <button onClick={darkMode.toggle}>
      {darkMode.value ? <img src={Desligada} alt='DESLIGADO' className=''/> : <img src={Ligada} alt='LIGADO' />}
    </button>
  );
}

export default UseDarkMode;
