import Produto1 from '../Img/produtos/prod1.jpg';
import Produto2 from '../Img/produtos/prod2.jpg';
import Produto3 from '../Img/produtos/prod3.jpg';
import Produto4 from '../Img/produtos/prod4.jpg';
import Produto5 from '../Img/produtos/prod5.jpg';
import Produto6 from '../Img/produtos/uma.jpg';

const InfoProdutos = [
    {
      id: 1,
      nome: "Cadeira Blaster",
      src: [Produto1],
      descricao: "Cadeira Gamer Blaster...",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/17uy3eJYziMyCeVLnC6nAPRauPd8WTq80/view"
    },
    {
      id: 2,
      nome: "Cadeira Brighter",
      src: [Produto2],
      descricao: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente, accusamus vitae non sed itaque autem sunt labore optio, delectus libero amet corporis incidunt, culpa porro. Corrupti labore itaque aperiam voluptas.",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/1fji0oxvo0Kzgo9DsfRu7cxOxWHuv74dr/view"
    },
    {
      id: 3,
      nome: "Cadeira Cosmic",
      src: [Produto3, Produto1, Produto2, Produto4],
      descricao: "Cadeira Gamer Cosmic...",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/1wwNQPqE6kfPcAsW7PeQtpVmJpiMAFSqs/view"
    },
    {
      id: 4,
      nome: "Cadeira Fury",
      src:[Produto4],
      descricao: "Cadeira Gamer Brighter...",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/13HlB_nkvq7UoH3NBOQwmvcUhsJjNe53H/view"
    },
    {
      id: 5,
      nome: "Cadeira Meteora",
      src:[Produto5],
      descricao: "Cadeira Gamer Brighter...",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/1e1sQd7GcXvNM-r3Qlx_z0yCzhN-YxSZA/view"
    },
    {
      id: 6,
      nome: "Cadeira Wake",
      src:[Produto6],
      descricao: "Cadeira Gamer Brighter...",
      link: "//www.maniavirtual.com.br/produtos/cadeira-gamer-prizi-runner-amarela/",
      manual: "https://drive.google.com/file/d/1CyNEdMMrGtvp86JZOBKrOlPlKcYF5Rx1/view"
    },
  ];
  export { InfoProdutos};
  